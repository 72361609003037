import React, { useState, useEffect } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendQuiz } from "../../../../../../api/quiz";
import ResultsDialog from "../../results-dialog";
import { useTranslation } from "react-i18next";
import { hasRole, getUser } from "../../../../../../utils/utils";
import { ROLE_STUDENT_STR } from "../../../../../../config/environment";

const alphabet = {
  0: "a",
  1: "b",
  2: "c",
  3: "d",
  4: "e",
  5: "f",
  6: "g",
  7: "h",
  8: "i",
  9: "j",
  10: "k",
  11: "l",
  12: "m",
  13: "n",
  14: "o",
  15: "p",
  16: "q",
  17: "r",
  18: "s",
  19: "t",
  20: "u",
  21: "v",
  22: "w",
  23: "x",
  24: "y",
  25: "z",
};

const Pairing = ({ quiz, subject, level }) => {
  const { t, i18n } = useTranslation(["quiz"]);
  const history = useHistory();
  const userCurrent = getUser();

  const { subscriptionType, user } = useSelector((state) => ({
    subscriptionType: state.subscriptionType,
    user: state.user,
  }));

  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState({});
  const [submited, setSubmited] = useState(null);
  const [lock, setLock] = useState(false);
  const [questionListRandom, setQuestionListRandom] = useState([]);

  useEffect(() => {
    const clone = [...quiz.QuestionRequestModelList];
    setQuestionListRandom(shuffle(clone));
  }, [quiz]);

  const handleFieldChange = (fieldId, value) => {
    setValues({ ...values, [fieldId]: value });
  };

  const handleSubmit = async () => {
    const alphaValues = Object.values(alphabet);
    const request = Object.entries(values).map((value) => ({
      QuizId: quiz.QuizModel.Id,
      QuestionId: value[0],
      ResponseId: convertToResposeElement(alphaValues, value[1]),
      SubjectCode: subject,
      LevelCode: level,
    }));
    setLock(true);
    try {
      const res = await sendQuiz(request, subscriptionType);
      setResult(res.data.UserQuizResultModel);
      handleClickOpen();
      setSubmited(true);
    } catch {
    } finally {
      setLock(false);
    }
  };

  const convertToResposeElement = (arr, value) => {
    const index = arr.findIndex((elem) => elem === value);
    let response;
    if (index !== -1) {
      response = questionListRandom.find((item, i) => i === index);
      return response ? response.QuizQuestionResponseModelList[0].Id : null;
    }
    return null;
  };

  const goBack = () => {
    switch (quiz.QuizModel.IsExam) {
      case "0":
        history.push(
          `/students/lesson/${quiz.QuizModel.LessonId}?tab=practice`
        );
        break;
      case "1":
        history.push(
          `/students/lesson/${quiz.QuizModel.LessonId}?tab=challenge`
        );
        break;
      default:
        history.goBack();
    }
  };

  function shuffle(array) {
    var tmp,
      current,
      top = array.length;

    if (top)
      while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
      }

    return array;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="wrapper-content">
        <div className="center-container">
          <div className="wrapper-iframe-form">
            <h4 className="title-page">{quiz.QuizModel.Title}</h4>

            <h1>{quiz.QuizModel.Title}</h1>
            <p className="instruction">
              <span
                dangerouslySetInnerHTML={{
                  __html: quiz.QuizModel.Instruction,
                }}
              />
            </p>
            {quiz.QuizExtraInfoModel && (
              <div
                dangerouslySetInnerHTML={{
                  __html: quiz.QuizExtraInfoModel.ExtraInfo,
                }}
              />
            )}

            <form className="content-pareo">
              <div className="pareo-col-left">
                <ul className="list-form">
                  {quiz.QuestionRequestModelList.map((item, i) => (
                    <li key={i}>
                      {i + 1}. ({" "}
                      <input
                        type="text"
                        name={`user${i}`}
                        disabled={submited}
                        placeholder="Ej. a"
                        onChange={(e) =>
                          handleFieldChange(
                            item.QuizQuestionModel.Id,
                            e.target.value.toLowerCase()
                          )
                        }
                      />{" "}
                      ){" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.QuizQuestionModel.Desc,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="pareo-col-right">
                <ul className="list-question">
                  {questionListRandom.map((item, i) => (
                    <li key={i}>
                      {alphabet[`${i}`]}.{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            item.QuizQuestionResponseModelList[0].Response,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </form>
            {!submited && hasRole(userCurrent, [ROLE_STUDENT_STR]) && (
              <div>
                <button
                  className="button-quiz"
                  type="button"
                  disabled={
                    Object.keys(values).length !==
                      quiz.QuestionRequestModelList.length || lock
                  }
                  onClick={handleSubmit}
                >
                  {t("submit")}
                </button>
                <button
                  className="button-quiz-back"
                  type="button"
                  onClick={goBack}
                >
                  {t("back")}
                </button>
              </div>
            )}
            {submited && (
              <button
                className="button-quiz-back"
                type="button"
                onClick={goBack}
              >
                {t("back")}
              </button>
            )}
            <ResultsDialog
              open={open}
              onClose={handleClose}
              result={result}
              isExam={quiz.QuizModel.IsExam}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pairing;
