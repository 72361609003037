import React, { useState, useEffect } from "react";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "react-select";
import {
  getFilesRules,
  sendMessage,
} from "../../../../api/message";
import { getContacts } from "../../../../api/chat";
import DropzoneMessage from "../dropzone-message";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { convertMaxLengthSting } from "../../../../utils/utils";

const MessageComponerDialg = ({ onClose, open, handleRefresh }) => {
  const { t, i18n } = useTranslation(["message"]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [filesSource, setFilesSource] = useState([]);
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [usersSelecteds, setUsersSelecteds] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  useEffect(() => {
    (async () => {
      const resFilesRules = await getFilesRules();
      const resContacts = await getContacts(page, pageSize);
      const users = resContacts.data.UserInfo;
      const usersMap = users.map((item) => ({
        value: item.UserId,
        label: `${item.GivenName} ${item.Surname}`,
      }));
      setUsers(usersMap);
    })();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    const recipientIds = usersSelecteds.map((item) => ({
      RecipientId: item.value,
    }));
    const res = await sendMessage(
      filesSource,
      null,
      description,
      subject,
      recipientIds
    );
    toastr.success("", res.data.Messages[0]);
    handleRefresh();
    handleClose();
  };

  const onChangeSubject = (e) => {
    const value = e.target.value;
    const newValue = convertMaxLengthSting(value, 100);
    setSubject(newValue);
  };

  const onChangeDescription = (e) => {
    const value = e.target.value;
    const newValue = convertMaxLengthSting(value, 500);
    setDescription(newValue);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogContent>
          <div className="modal-text-right">
            <button className="close-windows" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          <h1 className="title-modal-message">{t("newMessage")}</h1>

          <div className="modal-message--compose-body">
            <div className="selectTrello">
              <Select
                value={usersSelecteds}
                onChange={(sel) => setUsersSelecteds(sel)}
                options={users}
                placeholder={t("to")}
                isMulti={true}
              />
            </div>

            <input
              placeholder={t("subject")}
              name="subject"
              type="text"
              value={subject}
              onChange={onChangeSubject}
            />

            <div className="form-group-message-compose">
              <textarea
                id="message"
                placeholder={t("textReply")}
                value={description}
                onChange={onChangeDescription}
              ></textarea>
            </div>

            <div className="content-response-instruction">
              <DropzoneMessage setFilesSource={setFilesSource} />
              <h2>{t("validFile")}</h2>
              <p>
                jpg (Max: 98 KB) , jpeg (Max: 98 KB) , png (Max: 98 KB) , pdf
                (Max: 98 KB) , ogv (Max: 482 MB) , ogg (Max: 2 MB) , mp3 (Max: 2
                MB) , mp4 (Max: 95 MB) , doc (Max: 98 KB) , docx (Max: 98 KB) ,
                csv (Max: 98 KB) , xlsx (Max: 98 KB) , xlsm (Max: 98 KB)
              </p>
            </div>

            <button
              type="submit"
              className="button-modal-message-primary"
              disabled={!description || !subject || !usersSelecteds.length}
              onClick={handleSubmit}
            >
              {" "}
              {t("send")}{" "}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MessageComponerDialg;
