import React from "react";
import { useSelector } from "react-redux";
import { getNameSubject } from "../../../../utils/subject";

const Subject = ({
  subject,
  hadleSubjectSelected,
  subjectSelected,
}) => {

  const { subjects } = useSelector((state) => ({
    subjects: state.subjects,
  }));

  const imageLoad = require(`../../../../assets/images/${assignIcon(
    subject.SubjectCode
  )}`);

  function assignIcon(subject) {
    switch (subject) {
      case "EN":
        return "LessonEnglish-icon.svg";
      case "SP":
        return "LessonSpanish-icon.svg";
      case "MAT-EN":
        return "LessonMath-icon.svg";
      case "SCI-EN":
        return "LessonScience-icon.svg";
      case "MAT-SP":
        return "LessonMath-icon.svg";
      case "SCI-SP":
        return "LessonScience-icon.svg";
      case "SCI-SO":
        return "LessonSocialStudies-icon.svg";
      case "EDF-SP":
        return "LessonEducacionFisica-icon.svg";
      case "ART-SP":
        return "LessonBellasArtes-icon.svg";
      case "SLD-SP":
        return "LessonSalud-icon.svg";
      default:
        return "LessonDefault-icon.svg";
    }
  }

  return (
    <>
      <div className="general-subject__mod-lessons">
        <a
          className={
            subjectSelected &&
              subjectSelected.LevelCode === subject.LevelCode &&
              subjectSelected.SubjectCode === subject.SubjectCode
              ? "active"
              : ""
          }
          onClick={() => hadleSubjectSelected(subject)}
        >
          <div className="mod-lessons--left">
            <img src={imageLoad} />{" "}
            <span className="mod-lessons--title-lesson">{getNameSubject(subject.SubjectCode, subjects)}{" "}{subject.LevelCode}</span>
          </div>
          <div className="mod-lessons--right bg-average">
            <span className="mod-lessons--lesson-grade">
              <span className="average txt-average">
                {subject.Average}%{"  "}{subject.AcademicGrade}
              </span>
            </span>
          </div>
        </a>
      </div>
    </>
  );
};

export default Subject;
