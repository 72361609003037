import React, { useState, useEffect } from "react";
import "./style.css";
import { getMaterialAdditionalByLesson } from "../../../../../api/lesson";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import LessonTitle from "../lesson-title";
import { useTranslation } from "react-i18next";

const EmptyListStyled = styled.p`
  color: #ccc;
  display: block
  justify-content: center;
  text-align: center;
  margin: 3em auto;

  & i {
    display: block;
    margin: 1em auto 0.25em auto;
    font-size: 45px;
    width: 175px;
    opacity: .5;
  }
`;

const Material = ({ item }) => {
  const history = useHistory();
  const sp = item.AdditionalMaterialModel.Url.split("/");
  const file = sp[sp.length - 1];
  const ext = getFileExtension(file);
  const imageLoad = require(`../../../../../assets/images/${assignImage(ext)}`);

  function assignImage(ext) {
    switch (ext) {
      case "epub":
        return "Aditional-icon-digital.svg";
      case "doc":
        return "Aditional-icon-Word.svg";
      case "docx":
        return "Aditional-icon-Word.svg";
      case "mp3":
        return "Aditional-icon-youtube.svg";
      case "mp4":
        return "Aditional-icon-youtube.svg";
      case "pdf":
        return "Aditional-icon-PDF.svg";
      default:
        return "Aditional-icon-web.svg";
    }
  }

  function getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  return (
    <div className="aditional-list-w-icon__content">
      <div className="aditional-list__right">
        <img src={imageLoad} />
      </div>
      <div className="aditional-list__left">
        <p className="aditional-list__txt-ppal">
          {ext === "epub" ? (
            <a
              onClick={() =>
                history.push(
                  `/students/library/${item.AdditionalMaterialModel.Id}`
                )
              }
            >
              {" "}
              {item.AdditionalMaterialModel.Title}{" "}
            </a>
          ) : (
            <a href={item.AdditionalMaterialModel.Url} target="_blank">
              {" "}
              {item.AdditionalMaterialModel.Title}{" "}
            </a>
          )}
        </p>
        <p>{item.AdditionalMaterialModel.Description}</p>
      </div>
    </div>
  );
};

const AdditionalMaterial = ({
  lesson,
  kids,
  subject,
  lessonId,
  setLoading,
  loading,
}) => {
  const { t } = useTranslation(["detail-lesson"]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    getMaterialAdditional();
  }, []);

  const getMaterialAdditional = async () => {
    setLoading(true);
    try {
      const res = await getMaterialAdditionalByLesson(lessonId, page, pageSize);
      const data = res.data;
      setMaterials(data.AdditionalMaterials);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  let className = "wrapper-iframe";
  if (kids) {
    className = "wrapper-iframe-kids";
  }
  return (
    <>
      <div className="wrapper-content">
        <div className="center-container">
          <div className={className}>
            {lesson && <LessonTitle subject={subject} lesson={lesson} />}
            <div className="wrapper-aditional-list-w-icon">
              {materials.length !== 0 &&
                materials.map((item, i) => <Material key={i} item={item} />)}
            </div>

            {!loading && materials.length === 0 && (
              <EmptyListStyled>
                <i className="fas fa-book-reader"></i> {t("emptyAdditionalMaterial")}
              </EmptyListStyled>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalMaterial;
