import React, { useEffect, useState } from "react";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { getDetailCompletedAssignment } from "../../../../../api/lesson";
import { Loader } from "../../../../common/styled-components/loader";
import { useTranslation } from "react-i18next";

const SeeDetailDialog = ({ onClose, open, quizResultId }) => {
  const { t } = useTranslation(["task"]);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const [loading, setLoading] = useState(false);
  const [feedBack, setFeedBack] = useState("");

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    getDetailCompleted();
  }, []);

  const getDetailCompleted = async () => {
    setLoading(true);
    try {
      const res = await getDetailCompletedAssignment(quizResultId);
      const data = res.data;
      /** Modified by Portiz 2021.01.23: It was supposed to be Feedback, not FeedBack, otherwise, it wasn't read **/
      setFeedBack(data.Feedback);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogContent>
          <div className="modal-text-right">
            <button className="close-windows" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          <h2 className="comments-modal-title">{t("titleModalSeeDetail")}</h2>

          <div className="modal-comments-body">
            {feedBack ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: feedBack,
                }}
              />
            ) : (
              <div className="txt-no-comments">
                <i className="fas fa-comment-slash"></i>
                {t("emptyCommentsSeeDetail")}
              </div>
            )}
          </div>

          <div className="modal-footer-comments">
            <button
              type="submit"
              className="button-modal-message-secondary"
              onClick={handleClose}
            >
              {t("buttonCloseModalSeeDetail")}
            </button>
          </div>
          {loading && <Loader />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SeeDetailDialog;
