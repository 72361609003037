import React, { useState, useEffect } from "react";
import "./style.css";
import Subjects from "./subjects";
import Lessons from "./lessons";
import Menu from "../menu";
import { getSubjectsAndCoursesThuk } from "../../../redux/actions/subjects";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import AssignedLessons from "./assigned-lessons";
import { useTranslation } from "react-i18next";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Lesson = () => {
  const { t } = useTranslation(["task"]);
  const dispatch = useDispatch();
  let query = useQuery();
  const history = useHistory();
  let location = useLocation();
  const [tab, setTab] = useState(query.get("q") || "lessons");
  const { subjects } = useSelector((state) => ({
    subjects: state.subjects,
  }));

  useEffect(() => {
    dispatch(getSubjectsAndCoursesThuk());
  }, []);

  function handleChange(e) {
    const value = e.target.value;
    setTab(value);
    if (query.get("q")) {
      history.push(location.pathname);
    }
  }

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div className="wrapper-message-tabs content-max-width">
            <div className="message-tabs">
              <input
                id="lessons"
                type="radio"
                name="tabs"
                value="lessons"
                onChange={handleChange}
                checked={tab === "lessons"}
              />
              <label htmlFor="lessons" onChange={handleChange}>
                {t("lesson")}
              </label>
              <div className="message-tabs--content">
                {tab === "lessons" && (
                  <>
                    <div className="wrapper-lesson-tabs">
                      <div className="container_two-col">
                        <Subjects subjects={subjects} />
                      </div>
                      <div className="container_two-col">
                        <Lessons />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <input
                id="assigned"
                type="radio"
                name="tabs"
                value="assigned"
                onChange={handleChange}
                checked={tab === "assigned"}
              />
              <label
                className="tab-homework"
                htmlFor="assigned"
                onChange={handleChange}
              >
                {t("assignedLessons")}
              </label>
              <div className="message-tabs--content">
                {tab === "assigned" && <AssignedLessons />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lesson;
