import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import "./style-new-header.css";
import GenialSkills from "../../../assets/images/logo-GenialSkills.svg";
import TopBarMenu from "../../../assets/images/TopBarMenu-icon-help.svg";
import MenuIcon from "../../../assets/images/user-menu-icon.svg";
import UserIcon from "../../../assets/images/sample-avatar.png";
import GSApp from "../../../assets/images/logo_gs_game.png";
import DropdownUser from "../dropdown-user";
import { getUser } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { isMST } from "../../../utils/utils";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ClickupDialog from "../../common/clickup-dialog/clickup-dialog";
import { ENVIROMENT, BASE_URL_SITE_TEACHER } from "../../../config/environment";
import { useHistory, Link } from "react-router-dom";

export function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const Header = () => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const history = useHistory();
  const { t, i18n } = useTranslation(["home"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAPP, setAnchorElAPP] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAPP = (event) => {
    setAnchorElAPP(event.currentTarget);
  };

  const handleCloseAPP = () => {
    setAnchorElAPP(null);
  };

  const goToAPPUnity = () => {
    history.push("/students/gs-interactive");
    handleCloseAPP();
  };

  const openClickupDialog = () => {
    setOpen(true);
    handleClose();
  };

  const closeClickupDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="wrapper-header">
        <header className="main-header">
          <div className="main-header_GenialSkills-logo">
            <img src={GenialSkills} alt="" />
            {
               ENVIROMENT == "development" &&
              <span className="noticesDev">
                Dev
              </span>
              }
             {
              ENVIROMENT == "beta" &&
              <span className="noticesBeta">
                Beta
              </span>
              }
          </div>

          <div className="main-header_top-bar-menu">
            <nav className="top-bar-menu">
              <li>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <i className="fas fa-question-circle"></i>{" "}
                  <span>{t("help")}</span>
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <Link to="/faq">
                      {t("frequentQuestions")}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={openClickupDialog}>{t("clickupForm")}</MenuItem>
                </Menu>
              </li>

              <li className="header__icon-APP pr-1">
                {!isMST() && (<>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClickAPP}
                  >
                    <img src={GSApp} alt="" />
                    <span>
                      <strong> APP</strong>
                    </span>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorElAPP}
                    keepMounted
                    open={Boolean(anchorElAPP)}
                    onClose={handleCloseAPP}
                  >
                    <MenuItem onClick={handleCloseAPP}>
                      <a
                        href={`${BASE_URL_SITE_TEACHER}/#/download`}
                        target="_blank"
                      >
                        {t("download")}
                      </a>
                    </MenuItem>
                    {/* <MenuItem onClick={goToAPPUnity}>{t("gameOnline")}</MenuItem> */}
                  </Menu>
                </>)}
              </li>

              <li>
                <div className="user-comp">
                  <ul className="user-comp_profile-wrapper">
                    <li ref={ref}>
                      <div
                        className="user-comp_profile"
                        onClick={() =>
                          setIsComponentVisible(!isComponentVisible)
                        }
                      >
                        <span className="user-menu-icon">
                          <img src={MenuIcon} alt="" />
                        </span>
                        <div className="double-border--principal">
                          <img src={UserIcon} alt="" />
                        </div>
                        <span className="user-name">{getUser().Name}</span>
                      </div>
                      {isComponentVisible && (
                        <DropdownUser
                          setIsComponentVisible={setIsComponentVisible}
                          user={getUser()}
                        />
                      )}
                    </li>
                  </ul>
                </div>
              </li>
            </nav>
          </div>
        </header>
        {open && <ClickupDialog open={open} onClose={closeClickupDialog} />}
      </div>
    </>
  );
};

export default Header;
