import React from "react";
import "./style.css";
import "./progress-circle.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResultsDialog = ({ onClose, open, result, isExam }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(["quiz"]);

  const goBack = () => {
    history.goBack();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogContent>
          <div className="modal-text-right">
            <button className="close-windows" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <h1 className="title-modal">{t("results")}</h1>

          <div className="container-modal-body">
            <div className="modal-body_two-col">
              <h1 className="result-modal">
                {" "}
                {result.TotalCorrect}/{result.TotalQuestions}{" "}
                <span>
                  {t("try")}: {result.TryNo}
                </span>{" "}
              </h1>
            </div>

            <div className="modal-body_two-col">
              <div className={`c100 p${Math.round(result.TotalPercent)} big`}>
                <span>{result.TotalPercent}%</span>
                <div className="slice">
                  <div className="bar"></div>
                  <div className="fill"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            {isExam === "0" && (
              <button
                type="submit"
                className="button-modal-primary"
                onClick={handleClose}
              >
                {" "}
                {t("seeResponses")}{" "}
              </button>
            )}
            <button
              type="submit"
              className="button-modal-secondary"
              onClick={goBack}
            >
              {" "}
              {t("backLesson")}{" "}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResultsDialog;
