import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import "./tries-dialog.css";

const TriesDialog = ({ onClose, open, lesson, redirectQuiz }) => {
    const { t } = useTranslation(["detail-lesson"]);
    const [fullWidth] = useState(true);
    const [maxWidth] = useState("sm");

    const handleContinue = () => {
        redirectQuiz();
        onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
            >
                <DialogContent>
                    <h3 className="title-modal-tries">
                        {t("modalPreTestTitle")}
                    </h3>

                    <div className="modal-tries-body">
                        <p>
                            {t("modalPreTestBodyBefore")} <strong><em>{lesson && lesson.LessonTitle}</em></strong>. {t("modalPreTestBodyAfter")}
                        </p>
                    </div>

                    <div className="modal-footer-tries">
                        <button
                            type="button"
                            className="button-modal-message-primary"
                            onClick={handleContinue}
                        >
                            {t("modalPreTestContinue")}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TriesDialog;
