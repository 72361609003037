import React, { useState, useEffect } from "react";
import Menu from "../../menu";
import { useTranslation } from "react-i18next";
import { Calendar, momentLocalizer } from "react-big-calendar";
import CalendarDetailDialog from "./calendar-detail-dialog";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMsal } from "@azure/msal-react";
import { callMsGraphGetCalendar } from "../../../../graph";
import Header from "../header";
import { getTimeZone } from "../../../../utils/utils";
import moment from "moment";
import "moment-timezone";

const localizer = momentLocalizer(moment);

const CalendarEvent = () => {
  const { t } = useTranslation(["teams"]);
  const [eventsData, setEventsData] = useState([]);
  const { instance, accounts } = useMsal();
  const [positionDialog, setPositionDialog] = useState({
    top: 0,
    left: 0,
  });
  const [eventToDialog, setEventToDialog] = useState({});
  const [isDialogDetailVisible, setIsDialogDetailVisible] = useState(false);

  useEffect(() => {
    const startDateTime = moment()
      .startOf("week")
      .toISOString();
    const endDateTime = moment()
      .endOf("week")
      .toISOString();
    getCaledarEvents(startDateTime, endDateTime);
  }, []);

  const getCaledarEvents = (startDateTime, endDateTime) => {
    instance
      .acquireTokenSilent({
        scopes: ["Calendars.ReadWrite"],
      })
      .then((response) => {
        callMsGraphGetCalendar(
          response.accessToken,
          startDateTime,
          endDateTime
        ).then((response) => {
          const { value } = response;
          const events = value.map((item) => ({
            start: new Date(
              moment
                .utc(item.start.dateTime)
                .tz(getTimeZone())
                .toISOString()
            ),
            end: new Date(
              moment
                .utc(item.end.dateTime)
                .tz(getTimeZone())
                .toISOString()
            ),            
            isOrganizer: item.isOrganizer,
            title: item.subject,
            joinUrl: item.onlineMeeting && item.onlineMeeting.joinUrl,
            organizer: item.organizer && item.organizer.emailAddress.name,
            attendees: item.attendees && item.attendees
          }));
          setEventsData(events);
        });
      });
  };

  const handleRangeChange = (rangeDate) => {
    const startDateTime = moment(rangeDate[0]).toISOString();
    const endDateTime = moment(rangeDate[4]).toISOString();
    getCaledarEvents(startDateTime, endDateTime);    
  };

  const calculatePosition = (e) => {
    const { clientX, clientY } = e;
    const { innerWidth, innerHeight } = window;

    let position = {};
    if (clientX > innerWidth / 2) {
      position.right = `${innerWidth - clientX}px`;
    } else {
      position.left = `${clientX}px`;
    }

    if (clientY > innerHeight / 2) {
      position.bottom = `${innerHeight - clientY}px`;
    } else {
      position.top = `${clientY}px`;
    }

    return position;
  };

  const handleDetailMeet = (event, e) => {
    const position = calculatePosition(e);

    setPositionDialog(position);
    setEventToDialog(event)
    setIsDialogDetailVisible(true)
  }

  const handleCloseModalDetail = () => {
    setIsDialogDetailVisible(false);
  }

  const formats = {
    timeGutterFormat: (date, culture, localizer) =>
      localizer.format(date, 'h:mm A', culture),

    eventTimeRangeFormat: ({ start, end }, culture, local) => {
      let s = local.format(start, 'h:mm A', culture);
      let e = local.format(end, 'h:mm A', culture);
      return `${s} - ${e}`;
    },

    agendaTimeRangeFormat: ({ start, end }, culture, local) =>
      `${local.format(start, 'h:mm A', culture)} - ${local.format(end, 'h:mm A', culture)}`
  }

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div className="newMeetingContainer">
            <Header />
            <div className="container-teams">
              <Calendar
                views={["work_week"]}
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="work_week"
                events={eventsData}
                formats={formats}
                className="calendarTeams-Content"                
                onSelectEvent={handleDetailMeet}
                onRangeChange={handleRangeChange}
              />
            </div>
            <CalendarDetailDialog
              isVisible={isDialogDetailVisible}
              event={eventToDialog}
              positionDialog={positionDialog}
              onCloseModal={handleCloseModalDetail}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarEvent;
