import axios from "./interceptors";
import {
  PORT_API_GET_MESSAGE_UNREAD,
  PORT_API_GET_MESSAGE_RECEIVED,
  PORT_API_GET_MESSAGE_SENT,
  PORT_API_GET_DETAIL_MESSAGE,
  PORT_API_GET_FILES_RULES,
  PORT_API_MESSAGE_SEND,
  PORT_API_MESSAGE_MARK_READ,
  PORT_API_FILE_UPLOAD,
  PORT_API_CONNECTED_USERS,
} from "../config/environment";
import { getLanguage } from "../utils/utils";

export const getMessagesUnreads = () => {
  return axios.get(`${PORT_API_GET_MESSAGE_UNREAD}/api/messages/unreads`);
};

export const getMessagesReceiveds = (page, pageSize) => {
  page = page - 1;
  return axios.get(
    `${PORT_API_GET_MESSAGE_RECEIVED}/api/messages/received/${page}/${pageSize}/${getLanguage()}`
  );
};

export const getMessagesSents = (page, pageSize) => {
  page = page - 1;
  return axios.get(
    `${PORT_API_GET_MESSAGE_SENT}/api/messages/sent/${page}/${pageSize}/${getLanguage()}`
  );
};

export const getMessageDetail = (messageId) => {
  return axios.get(`${PORT_API_GET_DETAIL_MESSAGE}/api/messages/${messageId}`);
};

export const getFilesRules = () => {
  return axios.get(`${PORT_API_GET_FILES_RULES}/api/files/rules`);
};

export const fileUpload = (file) => {
  const formData = new FormData();
  formData.append("files[]", file);
  return axios.post(
    `${PORT_API_FILE_UPLOAD}/api/files/upload/${getLanguage()}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const markRead = (messageId) => {
  return axios.post(
    `${PORT_API_MESSAGE_MARK_READ}/api/messages/mark-read/${messageId}/${getLanguage()}`
  );
};

export const sendMessage = (
  filesSource,
  parentId,
  description,
  subject,
  recipientIds
) => {
  const data = {
    EmailMessageFullDetailModel: {
      EmailAttachmentModelList: filesSource,
      EmailMessageModel: {
        Body: description,
        ParentMessageId: parentId,
        Subject: subject,
      },
      EmailMessageRecipientModelList: recipientIds,
    },
    LangCode: getLanguage(),
  };
  return axios({
    method: "POST",
    url: `${PORT_API_MESSAGE_SEND}/api/messages/send`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};
