import React, { useEffect, useState } from "react";
import "./style.css";
import Select from "react-select";
import { currentGrade } from "../../../../redux/actions/grade";
import { getLessonsSuccess } from "../../../../redux/actions/lessons";
import { useSelector, useDispatch } from "react-redux";
import { getLanguage } from "../../../../utils/utils";
import SubjectItem from "../subject-item";
import { useTranslation } from "react-i18next";
import { getGradesOrdinals } from "../../../../utils/lesson";

const SelectTrelloGrade = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #E9E9E9",
    color: state.isSelected ? "#FFF" : "#6F6F6F",
    padding: 10,
    fontSize: 12,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const SubjectsHome = ({ subjects }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["home"]);
  const { grade, user } = useSelector((state) => ({
    grade: state.grade,
    user: state.user,
  }));
  const [gradeSelected, setGradeSelected] = useState(null);
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    const grades = Array.from(
      new Set(subjects.map((subject) => subject.Level))
    ).sort((a, b) => {
      return a - b;
    });
    const lists = grades.map((grade) => ({
      value: grade,
      label: getGradesOrdinals(grade, getLanguage()),
    }));
    setGrades(lists);
    setGradeSelected(
      grade
        ? { value: grade, label: getGradesOrdinals(grade, getLanguage()) }
        : {
            value: grades[0],
            label: getGradesOrdinals(grades[0], getLanguage()),
          }
    );
    if (!grade) {
      dispatch(currentGrade(grades.length ? grades[0] : null));
    }
  }, [subjects]);

  const handleChange = (selected) => {
    setGradeSelected(selected);
    dispatch(currentGrade(selected.value));
    dispatch(getLessonsSuccess([]));
  };

  return (
    <>
      <div className="wrapper-home-matter">
        <h1>
          {t("welcome")}, {user.Name}. {t("selectMatter")}
        </h1>
        <div className="home-matter-select">
          <div className="selectTrelloGrade">
            <Select
              styles={SelectTrelloGrade}
              value={gradeSelected}
              onChange={handleChange}
              options={grades}
              placeholder="Select Grade"
            />
          </div>
        </div>
        <div className="content-item-matter">
          {subjects
            .filter((course) => course.Level === grade)
            .map((subject, i) => (
              <SubjectItem key={i} subject={subject} />
            ))}
        </div>
      </div>
    </>
  );
};

export default SubjectsHome;
