import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import "./style.css";

const ConfirmDialog = ({ onClose, open, handleDeleteItem }) => {
  const { t } = useTranslation(["confirm"]);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        className="modal-delete"
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>{t("titleConfirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <i className="fas fa-exclamation-circle"></i>
            {t("description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="button-secondary"
            onClick={handleClose}
            color="primary"
          >
            {t("buttonCancel")}
          </Button>
          <Button
            className="button-primary"
            onClick={handleDeleteItem}
            color="primary"
            autoFocus
          >
            {t("buttonAccept")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
