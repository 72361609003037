import React, { useState } from "react";
import Menu from "../menu";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Progress from "./index";
import Scores from "./scores/scores";
import { Loader } from "../../common/styled-components/loader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProgressTabs = () => {
  const { t } = useTranslation(["progress"]);
  let query = useQuery();
  const history = useHistory();
  let location = useLocation();
  const [tab, setTab] = useState(query.get("q") || "progress");
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    const value = e.target.value;
    setTab(value);
    if (query.get("q")) {
      history.push(location.pathname);
    }
  }

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div className="wrapper-message-tabs content-max-width">
            <div className="message-tabs">
              <input
                id="progress"
                type="radio"
                name="tabs"
                value="progress"
                onChange={handleChange}
                checked={tab === "progress"}
              />
              <label htmlFor="progress" onChange={handleChange}>
                {t("tabProgress")}
              </label>
              <div className="message-tabs--content">
                {tab === "progress" && <Progress loading={loading} setLoading={setLoading} />}
              </div>

              <input
                id="average"
                type="radio"
                name="tabs"
                value="average"
                onChange={handleChange}
                checked={tab === "average"}                
              />
              <label
                className="tab-homework"
                htmlFor="average"
                onChange={handleChange}
              >
                {t("tabAverage")}
              </label>
              <div className="message-tabs--content">
                {tab === "average" && <Scores loading={loading} setLoading={setLoading} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default ProgressTabs;
