import React, { useState, useEffect } from "react";
import "./scores.css";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getNameSubject, removeDuplicates } from "../../../../utils/subject";
import {
  getAcademicYear,
  getAcademicYearGrades,
  getReportAcademicYearGrades
} from "../../../../api/lesson";
import { useTranslation } from "react-i18next";
import moment from 'moment';

const SelectGrade = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #E9E9E9",
    color: state.isSelected ? "#FFF" : "#6F6F6F",
    padding: 10,
    fontSize: 12,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const Scores = ({ loading, setLoading }) => {

  const { t } = useTranslation(["progress"]);
  const [academicYearsList, setAcademicYearsList] = useState([]);
  const [academicYearSelected, setAcademicYearSelected] = useState({});
  const [grades, setGrades] = useState([]);
  const [cummulative, setCummulative] = useState([]);
  const [averagePerPeriod, setAveragePerPeriod] = useState([]);

  const { subjects } = useSelector((state) => ({
    subjects: state.subjects,
  }));

  useEffect(() => {
    getAcademicYearUser();
  }, []);

  const getAcademicYearUser = async () => {
    setLoading(true);
    try {
      const res = await getAcademicYear();
      const data = res.data;
      const options = data.map(item => {
        return {
          value: item.Id,
          label: moment(item.FullTermStartDate, "M/DD/YYYY hh:mm:ss A").format("MM/DD/YYYY") + " - " + moment(item.FullTermEndDate, "M/DD/YYYY hh:mm:ss A").format("MM/DD/YYYY")
        };
      });
      setAcademicYearsList(options);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleChangeAcademicYear = (selected) => {
    setAcademicYearSelected(selected);
    getGradesByAcademicYear(selected.value);
  };

  const getGradesByAcademicYear = async (academicYearId) => {
    setLoading(true);
    try {
      const res = await getAcademicYearGrades(academicYearId);
      const data = res.data;

      setCummulative(data.cummulative);

      const avgpp = data.average_per_period.map(item => {
        return {
          score: item.score.toFixed(2),
          grade: item.grade,
          period_no: item.period_no
        };
      });
      setAveragePerPeriod(avgpp);

      const subjectCodes = removeDuplicates(data.period_details.map(item => ({ subject_code: item.subject_code, level_code: item.level_code })));

      let subjectGrades = [];
      const formatted = data.period_details.map(item => {
        return {
          subject_code: item.subject_code,
          grade: item.grade,
          score: item.score.toFixed(2),
          level_code: item.level_code
        };
      });

      subjectCodes.forEach(element => {
        let filtered = formatted.filter(item => item.subject_code === element.subject_code && item.level_code === element.level_code);
        subjectGrades.push({
          subject_code: element.subject_code,
          level_code: element.level_code,
          subject_name: getNameSubject(element.subject_code, subjects),
          grades: filtered,
        });
      });
      setGrades(subjectGrades);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const downloadPDF = async (academicYearId) => {
    setLoading(true);
    try {
      const res = await getReportAcademicYearGrades(academicYearId);
      const url = res.data;
      if (url) {
        window.open(url, "_blank");
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="wrapper-col space-between">
        <h1 className="title-table-progress">{t("yearAcademic")}</h1>
        <div className="title-search__col-right">
          <div className="selectTrelloGrade">
            <Select
              styles={SelectGrade}
              onChange={handleChangeAcademicYear}
              options={academicYearsList}
              placeholder={t("selectYear")}
            />
          </div>
        </div>
      </div>
      {averagePerPeriod.length > 0 && <div className="wrapper-table-homework">
        <div className="wrapper-download">
          <button type="submit" className="button-primary" onClick={() => downloadPDF(academicYearSelected.value)}>
            {t("download")}
          </button>
        </div>

        <table className="content-table-homework content-table-progress">
          <thead>
            <tr>
              <th>{t("subject")}</th>
              {averagePerPeriod.map((item, i) => (
                <th key={i}>{t("periodNro")} {item.period_no}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {grades.map((grade, i) => (
              <tr key={i}>
                <td className="table-progress--title">{grade.subject_name} {" "} {grade.level_code}</td>
                {grade.grades.map((x, j) =>
                  <td key={j}>
                    {<span>{x?.score} %{" "} <span className="letter-progress">{x?.grade}</span> </span>}
                  </td>
                )}
              </tr>
            ))}

            <tr className="footer-table-progress">
              <td className="table-progress--title">{t("average")}</td>
              {averagePerPeriod.map((item, i) => (
                <td key={i}>
                  {<span>{item?.score} % {" "} <span className="letter-progress">{item?.grade}</span></span>}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>}

      {cummulative.length > 0 && (<>
        <div className="wrapper-col space-between">
          <h1 className="title-table-progress">{t("cumulativeAve")}</h1>
        </div>
        <div className="wrapper-table-homework">
          <table className="content-table-homework content-table-progress">
            <thead>
              <tr>
                <th>{t("subject")}</th>
                <th>{t("cumulative")}</th>
              </tr>
            </thead>
            <tbody>
              {cummulative.map((item, i) => (
                <tr key={i}>
                  <td className="table-progress--title">{getNameSubject(item.subject_code, subjects)} {" "} {item.level_code}</td>
                  <td>{<span>{item?.score} % {" "} <span className="letter-progress">{item?.grade}</span> </span>}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div></>)}
    </>
  );
};

export default Scores;
