import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { BASE_URL_SITE_TEACHER } from "../../../config/environment";

const Footer = () => {
  const { t, i18n } = useTranslation(["home"]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <div className="wrapper-footer">
        <footer className="main-footer-students">
          <div className="col-right--copyright">
            <a
              className="red-link"
              href={`${BASE_URL_SITE_TEACHER}/#/privacy`}
              target="_blank"
            >
              <span className="icon-coppa"><i className="fas fa-child"></i></span>
              <span className="txt-copyright">{t("coppa")}</span>
            </a>{" "}
            | <span className="txt-copyright">{t("service")}</span> <i className="fas fa-phone"></i> (787)
            231-0047
          </div>

          <div className="col-left--footer-laguages">
            <ul className="footer-languages_languages-students">
              <li>
                <i className="fas fa-globe"></i>
              </li>
              <li>
                <a onClick={() => changeLanguage("es")}>
                  {" "}
                  Esp<span>añol</span>{" "}
                </a>
              </li>
              <li>
                <a onClick={() => changeLanguage("en")}>
                  {" "}
                  Eng<span>lish</span>{" "}
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
