import React from "react";
import "./style.css";
import HeaderLogin from "../../login/header";
import FooterLogin from "../../login/footer";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Faq from "react-faq-component";
import { HTTP_USER_MANUAL_STUDENTS } from "../../../config/environment";

const FaqStudents = () => {
  const history = useHistory();
  const { t } = useTranslation(["faq"]);

  const data = {
    title: `${t("title-student")}`,
    rows: [
      {
        title: `${t("student-password-forgot")}`,
        content: `${t("student-password-forgot-exp")}`,
      },
      {
        title: `${t("student-access-tests")}`,
        content: `${t("student-access-tests-exp")}`,
      },
      {
        title: `${t("student-lessons-assignments")}`,
        content: `${t("student-lessons-assignments-exp")}`,
      },
      {
        title: `${t("student-assignments-completed")}`,
        content: `${t("student-assignments-completed-exp")}`,
      },
      {
        title: `${t("student-grades")}`,
        content: `${t("student-grades-exp")}`,
      },
      {
        title: `${t("student-forums")}`,
        content: `${t("student-forums-exp")}`,
      },
      {
        title: `${t("student-forums-2")}`,
        content: `${t("student-forums-2-exp")}`,
      },
      {
        title: `${t("student-chat")}`,
        content: `${t("student-chat-exp")}`,
      },
      {
        title: `${t("student-comments")}`,
        content: `${t("student-comments-exp")}`,
      },
      {
        title: `${t("student-library")}`,
        content: `${t("student-library-exp")}`,
      },
      {
        title: `${t("student-what-is-app")}`,
        content: `${t("student-what-is-app-exp")}`,
      },
      {
        title: `${t("student-notifications")}`,
        content: `${t("student-notifications-exp")}`,
      },
      {
        title: `${t("student-connection-problem")}`,
        content: `${t("student-connection-problem-exp")}`,
      },
      {
        title: `${t("student-forum")}`,
        content: `${t("student-forum-exp")}`,
      },
      {
        title: `${t("student-incident-report")}`,
        content: `${t("student-incident-report-exp")}`,
      },
      {
        title: `${t("student-average")}`,
        content: `${t("student-average-exp")}`,
      },
      {
        title: `${t("student-profile")}`,
        content: `${t("student-profile-exp")}`,
      },
      {
        title: `${t("student-subtitles")}`,
        content: `${t("student-subtitles-exp")}`,
      },
      {
        title: `${t("student-lesson")}`,
        content: `${t("student-lesson-exp")}`,
      },
    ],
  };

  const downloadManual = () => {
    window.open(HTTP_USER_MANUAL_STUDENTS, "_blank");
  };

  return (
    <>
      <HeaderLogin />
      <div className="wrapper-container-login">
        <div className="container-faq mt-1">
          <h2>
            <i className="fas fa-question"></i> {t("faqTitle")}
          </h2>
          <hr />
          <button
            type="submit"
            className="button-secondary"
            onClick={downloadManual}
          >
            Para descargar el manual de usuario, presione aquí.
          </button>
          <Faq data={data} />
        </div>
      </div>
      <FooterLogin />
    </>
  );
};

export default FaqStudents;
