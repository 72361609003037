import React from "react";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  getMessageDetail,
  getFilesRules,
  markRead,
  sendMessage,
} from "../../../../api/message";
import DropzoneMessage from "../dropzone-message";
import { useDispatch, useSelector } from "react-redux";
import { getMessagesUnreadsThunk } from "../../../../redux/actions/unread";
import MessageContent from "../message-content";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { convertMaxLengthSting } from "../../../../utils/utils";

const MessageResponse = ({ message, handleClose, getMetadataReceiveds }) => {
  const { t, i18n } = useTranslation(["message"]);
  const [filesSource, setFilesSource] = React.useState([]);
  const [description, setDescription] = React.useState("");

  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      const res = await getFilesRules();
      const data = res.data.EmailMessageFullDetailModel;
    })();
  }, []);

  const handleSubmit = async () => {
    const res = await sendMessage(
      filesSource,
      message.Id,
      description,
      message.Subject,
      [{ RecipientId: message.AuthorId }]
    );
    toastr.success("", res.data.Messages[0]);
    getMetadataReceiveds();
    dispatch(getMessagesUnreadsThunk());
    handleClose();
  };

  const onChangeDescription = (e) => {
    const value = e.target.value;
    const newValue = convertMaxLengthSting(value, 500);
    setDescription(newValue);
  };

  return (
    <>
      <div className="container-modal-message__content-response">      

        <div className="form-group-message">
          <textarea
            id="response"
            placeholder={t("textReply")}
            value={description}
            onChange={onChangeDescription}
          ></textarea>
        </div>

        <div className="content-response-instruction">
          <DropzoneMessage setFilesSource={setFilesSource} />
          <h2>{t("validFile")}</h2>
          <p>
            jpg (Max: 98 KB) , jpeg (Max: 98 KB) , png (Max: 98 KB) , pdf (Max:
            98 KB) , ogv (Max: 482 MB) , ogg (Max: 2 MB) , mp3 (Max: 2 MB) , mp4
            (Max: 95 MB) , doc (Max: 98 KB) , docx (Max: 98 KB) , csv (Max: 98
            KB) , xlsx (Max: 98 KB) , xlsm (Max: 98 KB)
          </p>
        </div>

        <button
          type="submit"
          className="button-modal-message-primary"
          disabled={!description}
          onClick={handleSubmit}
        >
          {" "}
          {t("send")}{" "}
        </button>
      </div>
    </>
  );
};

const MessageDetailDialg = ({
  onClose,
  open,
  messageId,
  read,
  getMetadataReceiveds,
}) => {
  const { t, i18n } = useTranslation(["message"]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [metadataMessage, setMetadataMessage] = React.useState({});
  const [toogle, setToogle] = React.useState(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      const res = await getMessageDetail(messageId);
      const data = res.data.EmailMessageFullDetailModel;
      setMetadataMessage(data);
      if (!read) {
        await markRead(messageId);
        getMetadataReceiveds();
        dispatch(getMessagesUnreadsThunk());
      }
    })();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleToogle = () => {
    setToogle(!toogle);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogContent>
          <div className="modal-text-right">
            <button className="close-windows" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          <MessageContent metadataMessage={metadataMessage} />

          <div className="modal-footer text-align-right">
            <button
              type="submit"
              className="button-modal-message-primary"
              onClick={handleToogle}
            >
              {" "}
              {t("reply")}{" "}
            </button>
            <button
              type="submit"
              className="button-modal-message-secondary"
              onClick={handleClose}
            >
              {" "}
              {t("cancel")}{" "}
            </button>
          </div>

          {toogle ? (
            <MessageResponse
              handleClose={handleClose}
              message={metadataMessage.EmailMessageModel}
              getMetadataReceiveds={getMetadataReceiveds}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MessageDetailDialg;
