import React from "react";
import "./style.css";
import currentSubject from "../../../../redux/actions/subject";
import { getLessonsBySubjectsAndGradesThuk } from "../../../../redux/actions/lessons";
import { useDispatch, useSelector } from "react-redux";
import { assignColor } from "../../../../utils/lesson";

const SubjectItem = ({ subject }) => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.subject);

  const hadleSubjectSelected = () => {
    dispatch(currentSubject(subject));
    dispatch(getLessonsBySubjectsAndGradesThuk(subject.Level, subject.Subject));
  };

  const imageLoad = require(`../../../../assets/images/${assignIcon(
    subject.Subject
  )}`);

  function assignIcon(subject) {
    switch (subject) {
      case "EN":
        return "LessonEnglish-icon.svg";
      case "SP":
        return "LessonSpanish-icon.svg";
      case "MAT-EN":
        return "LessonMath-icon.svg";
      case "SCI-EN":
        return "LessonScience-icon.svg";
      case "MAT-SP":
        return "LessonMath-icon.svg";
      case "SCI-SP":
        return "LessonScience-icon.svg";
      case "SCI-SO":
        return "LessonSocialStudies-icon.svg";
      case "EDF-SP":
        return "LessonEducacionFisica-icon.svg";
      case "ART-SP":
        return "LessonBellasArtes-icon.svg";
      case "SLD-SP":
        return "LessonSalud-icon.svg";
      default:
        return "LessonDefault-icon.svg";
    }
  }

  return (
    <>
      <div className="wrapper-lessons">
        <a
          className={
            item.Level === subject.Level && item.Subject === subject.Subject
              ? "item-active"
              : ""
          }
          onClick={hadleSubjectSelected}
        >
          <div className="col-left">
            <img src={imageLoad} />{" "}
            <span className="title-lesson">{subject.SubjectName}</span>
          </div>
          <div
            className="col-right"
            style={{ backgroundColor: `${assignColor(subject.Subject)}` }}
          >
            <svg viewBox="0 0 57 105.6">
              <path
                className="arrow-right"
                style={{ fill: `${assignColor(subject.Subject)}` }}
                d="M56.6,105.6V0.1h-6.4L5.6,44.7C1,49.2,1,56.6,5.6,61.1L50,105.6H56.6z"
              />
            </svg>
            <span className="lesson-grade">{subject.Level}</span>
          </div>
        </a>
      </div>
    </>
  );
};

export default SubjectItem;
