import axios from "./interceptors";
import {
  PORT_API_HOMEWORK_AUTHORIZATION_CODE,
  PORT_API_GET_HOMEWORK_QUIZ,
  PORT_API_GET_FILES_RULES_HOMEWORK,
  PORT_API_HOMEWORK_QUIZ_CORRECTION,
  PORT_API_FILE_UPLOAD_QUIZ,
} from "../config/environment";
import { getLanguage } from "../utils/utils";

export const homeworkAuthorizationCode = (teacherPlanQuizId) => {
  return axios.post(
    `${PORT_API_HOMEWORK_AUTHORIZATION_CODE}/api/homework/authorization-code/create/${teacherPlanQuizId}`
  );
};

export const getQuizHomework = (authorizationCode) => {
  return axios.get(
      `${PORT_API_GET_HOMEWORK_QUIZ}/api/homework/quiz/student-quiz/${authorizationCode}`
  );
};

export const getFileRules = () => {
  return axios.get(
    `${PORT_API_GET_FILES_RULES_HOMEWORK}/api/files/file-rules/`
  );
};

export const sendQuizHomework = (code, questions) => {
  const data = {
    AuthorizationCode: code,
    UserQuizFullDetailModel: {
      QuestionQuizList: questions,
    },
  };
  return axios({
    method: "POST",
    url: `${PORT_API_HOMEWORK_QUIZ_CORRECTION}/api/homework/student-quiz/correction`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const fileUpload = (file, code) => {
  const formData = new FormData();
  formData.append("files[]", file);
  return axios.post(
    `${PORT_API_FILE_UPLOAD_QUIZ}/api/files/quiz/upload/${code}/${getLanguage()}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
