import React from "react";
import "./not-authorized.css";
import { useTranslation } from "react-i18next";

const NotAuthorized = () => {
  const { t } = useTranslation(["home"]);

  return (
    <>
      <div className="wrapper-not-authorized">
        <div className="not-authorized">
          <div id="page">
            <div id="content">

              <div className="DosCol">
                <div className="mod-txt">
                  <h1>ERROR 403</h1>
                  <h2>{t("title1403")}</h2>
                  <p>{t("title2403")}</p>
                  <p>{t("title3403")}</p>
                </div>
              </div>
              <div className="DosCol"><img src={require("../../../assets/images/403-image.png")} /></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotAuthorized;
