import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { sendQuiz } from "../../../../../../api/quiz";
import ResultsDialog from "../../results-dialog";
import TimeLimitDialog from "../../time-limit-dialog/time-limit-dialog";
import { useTranslation } from "react-i18next";
import "./style.css";
import { hasRole, getUser } from "../../../../../../utils/utils";
import { ROLE_STUDENT_STR } from "../../../../../../config/environment";
import moment from "moment";
import "moment-timezone";
import { toastr } from 'react-redux-toastr';
import { getTimeZone } from "../../../../../../utils/utils";

const toastrOptions = {
  timeOut: 30000,
  showCloseButton: true,
  removeOnHover: true,
  removeOnHoverTimeOut: 30000,
}


const Item = ({ item, index, onChange, submited, isExam, responsesSend }) => {

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(item.QuizQuestionModel.Id, value);
  };

  const mark = responsesSend.find(elem => elem.QuestionId === item.QuizQuestionModel.Id);

  return (
    <>
      <form className="content-form">
        <div
          className="quiz-title only-image-inline"
          dangerouslySetInnerHTML={{
            __html: item.QuizQuestionModel.Desc,
          }}
        />
        {item.QuizQuestionResponseModelList.map((res, i) => (
          <span className="quiz_radio-button" key={i}>
            {!submited ? (<input
              type="radio"
              value={res.Id}
              name={`radio${index}`}
              onChange={handleChange}
            />) : (<input
              type="radio"
              className={
                res.Correct === "1" && isExam === "0" ? "approved" : ""
              }
              disabled={submited}
              value={res.Id}
              name={`radio${index}`}
              checked={mark && mark.ResponseId === res.Id}
            />
            )}
            <label htmlFor={`res${i}`}>
              <div className="only-image-inline"
                dangerouslySetInnerHTML={{
                  __html: res.Response,
                }}
              />
            </label>
          </span>
        ))}
      </form>
    </>
  );
};

let timeLimitTimeout;
let timeLimitWarnTimeout;
let timeLimitDangerTimeout;

const Selection = ({ quiz, subject, level, getResponseQuiz }) => {
  const { t, i18n } = useTranslation(["quiz"]);
  const history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  let h = query.get("h");
  const dataTimeIso = h ? moment(h).tz(getTimeZone())
    .format("MM/DD/YYYY hh:mm:ss a") : "";
  const userCurrent = getUser();

  const { subscriptionType, user } = useSelector((state) => ({
    subscriptionType: state.subscriptionType,
    user: state.user,
  }));

  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState(null);
  const [submited, setSubmited] = useState(null);
  const [lock, setLock] = useState(false);
  const [openSorry, setOpenSorry] = useState(false);
  const [classTimeLimit, setClassTimeLimit] = useState("status-time-test--process");
  const [responsesSend, setResponsesSend] = useState([]);

  const handleFieldChange = (fieldId, value) => {
    setValues({ ...values, [fieldId]: value });
  };

  useEffect(() => {
    if (h && quiz.QuizModel.IsExam === "1") {
      const minutesDiff = moment(h).diff(moment(new Date().toISOString()), 'minutes');
      const seconds = minutesDiff * 60;
      setClassTimeLimit(getStyleWarning(minutesDiff));
      if (minutesDiff >= 0) {
        const hour = moment(h).tz(getTimeZone())
          .format("hh:mm:ss a");
        const message = t("messageWarnTimeLimit", { hour });
        if (minutesDiff >= 15) {
          const minutesWarn = moment(h).subtract(15, 'minutes').diff(moment(new Date().toISOString()), 'minutes');
          const secondsWarn = minutesWarn * 60;
          timeLimitWarnTimeout = setTimeout(() => {
            toastr.warning(message, toastrOptions);
            setClassTimeLimit("status-time-test--pending");
          }, secondsWarn * 1000);
        }
        if (minutesDiff >= 5) {
          const minutesDanger = moment(h).subtract(5, 'minutes').diff(moment(new Date().toISOString()), 'minutes');
          const secondsDanger = minutesDanger * 60;
          timeLimitDangerTimeout = setTimeout(() => {
            toastr.warning(message, toastrOptions);
            setClassTimeLimit("status-time-test--alert");
          }, secondsDanger * 1000);
        }
        timeLimitTimeout = setTimeout(() => {
          handleClickOpenSorry();
          setClassTimeLimit("status-time-test--alert");
        }, seconds * 1000);
      } else {
        handleClickOpenSorry();
      }
    }
    return () => {
      if (timeLimitTimeout) {
        clearTimeout(timeLimitTimeout);
      }
      if (timeLimitWarnTimeout) {
        clearTimeout(timeLimitWarnTimeout);
      }
      if (timeLimitDangerTimeout) {
        clearTimeout(timeLimitDangerTimeout);
      }
    };
  }, []);

  const getStyleWarning = (minutesDiff) => {
    if (minutesDiff >= 15) {
      return "status-time-test--process";
    } else if (minutesDiff >= 5 && minutesDiff < 15) {
      return "status-time-test--pending";
    } else {
      return "status-time-test--alert";
    }
  };

  const handleSubmit = async () => {
    const request = Object.entries(values).map((value) => ({
      QuizId: quiz.QuizModel.Id,
      QuestionId: value[0],
      ResponseId: value[1],
      SubjectCode: subject,
      LevelCode: level,
    }));
    setLock(true);
    try {
      const res = await sendQuiz(request, subscriptionType);
      setResult(res.data.UserQuizResultModel);
      handleClickOpen();
      setSubmited(true);
      getResponseQuiz(quiz.QuizModel.IsExam);
      setResponsesSend(request);
    } catch {
    } finally {
      setLock(false);
    }
  };

  const goBack = () => {
    switch (quiz.QuizModel.IsExam) {
      case "0":
        history.push(
          `/students/lesson/${quiz.QuizModel.LessonId}?tab=practice`
        );
        break;
      case "1":
        history.push(
          `/students/lesson/${quiz.QuizModel.LessonId}?tab=challenge`
        );
        break;
      default:
        history.goBack();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenSorry = () => {
    setOpenSorry(true);
  };

  const handleCloseSorry = () => {
    setOpenSorry(false);
  };

  return (
    <>
      <div className="wrapper-content">
        <div className="center-container">
          <div className="wrapper-iframe-form">
            <h4 className="title-page">{quiz.QuizModel.Title}</h4>
            {quiz.QuizModel.IsExam === "1" && <div className={`status-time-test ${classTimeLimit}`}>{t("timeLimitParte1")} <strong>{dataTimeIso}</strong> {t("timeLimitParte2")}</div>}
            {result && quiz.QuizModel.IsExam === "2" || result && quiz.QuizModel.IsExam === "3" ? null : <><h1>{quiz.QuizModel.Title}</h1>
              <p className="instruction">
                <span
                  dangerouslySetInnerHTML={{
                    __html: quiz.QuizModel.Instruction,
                  }}
                />
              </p>
              {quiz.QuizExtraInfoModel && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: quiz.QuizExtraInfoModel.ExtraInfo,
                  }}
                />
              )}

              {quiz.QuestionRequestModelList.map((item, i) => (
                <Item
                  key={i}
                  item={item}
                  index={i}
                  submited={submited}
                  isExam={quiz.QuizModel.IsExam}
                  onChange={handleFieldChange}
                  responsesSend={responsesSend}
                />
              ))}
              {!submited && hasRole(userCurrent, [ROLE_STUDENT_STR]) && (
                <div>
                  <button
                    className="button-quiz"
                    type="button"
                    disabled={
                      Object.keys(values).length !==
                      quiz.QuestionRequestModelList.length || lock
                    }
                    onClick={handleSubmit}
                  >
                    {t("submit")}
                  </button>
                  <button
                    className="button-quiz-back"
                    type="button"
                    onClick={goBack}
                  >
                    {t("back")}
                  </button>
                </div>
              )}
              {submited && (
                <button
                  className="button-quiz-back"
                  type="button"
                  onClick={goBack}
                >
                  {t("back")}
                </button>
              )}
              {open && (<ResultsDialog
                open={open}
                onClose={handleClose}
                result={result}
                isExam={quiz.QuizModel.IsExam}
              />)}</>}

            {result && quiz.QuizModel.IsExam === "2" ? (<div className="container-message">
              <p><i className="icon-message-test fas fa-check-circle"></i></p>
              <p><strong>{t("endQuiz")}</strong></p>
              <p>{t("readyQuiz")}</p>
              <button
                className="button-quiz"
                type="button"
                onClick={goBack}
              >
                {t("buttonContinueLesson")}
              </button>
            </div>) : null}

            {result && quiz.QuizModel.IsExam === "3" ? (<div className="container-message">
              <p><i className="icon-message-test fas fa-check-circle"></i></p>
              <p><strong>{t("endQuizPost")}</strong></p>
              <p>{t("readyPostTest")}</p>
              <button
                className="button-quiz"
                type="button"
                onClick={goBack}
              >
                {t("back")}
              </button>
            </div>) : null}

            {openSorry && (<TimeLimitDialog
              open={openSorry}
              onClose={handleCloseSorry}
              onGoBack={goBack}
            />)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Selection;

