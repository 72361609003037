export function assignIcon(subject) {
  switch (subject) {
    case "EN":
      return "LessonEnglish-icon.svg";
    case "SP":
      return "LessonSpanish-icon.svg";
    case "MAT-EN":
      return "LessonMath-icon.svg";
    case "SCI-EN":
      return "LessonScience-icon.svg";
    case "MAT-SP":
      return "LessonMath-icon.svg";
    case "SCI-SP":
      return "LessonScience-icon.svg";
    case "SCI-SO":
      return "LessonSocialStudies-icon.svg";
    case "EDF-SP":
      return "LessonEducacionFisica-icon.svg";
    case "ART-SP":
      return "LessonBellasArtes-icon.svg";
    case "SLD-SP":
      return "LessonSalud-icon.svg";
    default:
      return "LessonDefault-icon.svg";
  }
}

export const getNameSubject = (subjectCode, subjects) => {
  let subject = subjects.find((sub) => sub.Subject === subjectCode);
  if (subject) {
    return subject.SubjectName;
  }
  return "";
};

export const removeDuplicates = (arr) => {
  
  const arrMap = arr.map(elemento => {
    return [JSON.stringify(elemento), elemento]
  });

  return [...new Map(arrMap).values()];
}
