import axios from "./interceptors";
import {
  PORT_API_GET_QUIZ,
  PORT_API_SUBMIT_QUIZ,
  PORT_API_GET_QUIZ_AVAILABLE
} from "../config/environment";
import { getLanguage, getUser } from "../utils/utils";

export const getQuizById = (quizId, response = 0) => {
  return axios.get(`${PORT_API_GET_QUIZ}/api/quizzes/quiz/${quizId}/true?response=${response}`);
};

export const getQuizAvailableById = (quizId) => {
  return axios.get(`${PORT_API_GET_QUIZ_AVAILABLE}/api/planning/students/quiz/${quizId}/available?allowGraceTime=false`);
};

export const sendQuiz = (quizList, subscriptionType) => {
  const data = {
    LangCode: getLanguage(),
    Token: getUser().Token,
    UserResponseModelList: quizList,
  };
  return axios({
    method: "POST",
    url: `${PORT_API_SUBMIT_QUIZ}/api/quiz/student/submit/?subscriptionType=${subscriptionType}`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};
