import React from "react";
import "./style.css";
import avatar from "../../../assets/images/sample-avatar.png";
import LoginSuscribe from "../../../assets/images/icon-suscribe.svg";
import LoginInstitucional from "../../../assets/images/icon-institucional.svg";
import AzureIcon from "../../../assets/images/icon-Microsoft.svg";
import LoginTeacher from "../../../assets/images/icon-teacher.svg";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, loginSuccess } from "../../../redux/actions/user";
import loading from "../../../redux/actions/loading";
import { useTranslation } from "react-i18next";
import { TextDangerStyled } from "./style";
import { BASE_URL_SITE_TEACHER, IS_APP_MANAGED_BY_REQUIRED } from "../../../config/environment";
import { loginSso } from "../../../api/user";
import { useMsal } from "@azure/msal-react";

const LoginForm = () => {
  const history = useHistory();
  const { instance } = useMsal();
  const { register, errors, handleSubmit } = useForm();  

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["login"]);

  const onSubmit = (data) => {
    dispatch(login(data, history));
  };

  const handleLogin = () => {
    instance
      .loginPopup()
      .then(async (response) => {
        console.log("Login success:", response);
        instance.setActiveAccount(response.account);
        const { idToken } = response;
        await loginSsoAzure(idToken);
      })
      .catch((error) => {
        console.log("Login failed:", error);
      });
  };

  const loginSsoAzure = async (idToken) => {
    dispatch(loading(true));
    try {
      const res = await loginSso(idToken);
      const session = res.data;
      localStorage.setItem("auth", JSON.stringify(session));
      dispatch(loginSuccess(session));
      dispatch(loading(false));
      history.push("/students/home");
    } catch (error) {
      dispatch(loading(false));
    }
  };

  return (
    <>
      <div className="wrapper-container-login">
        <div className="container-login">
          <div className="col-login-left">
            <div className="module-w-padding">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container-login_double-border">
                  <img src={avatar} alt="" />
                </div>

                <h1 className="container-login_title"> {t("loginUser")} </h1>
                <input
                  type="text"
                  name="username"
                  placeholder={t("user")}
                  ref={register({
                    required: {
                      value: true,
                      message: t("validateUserRequired"),
                    },
                  })}
                />

                {errors.username && (
                  <TextDangerStyled>{errors.username.message}</TextDangerStyled>
                )}
                <input
                  type="password"
                  name="password"
                  placeholder={t("password")}
                  ref={register({
                    required: {
                      value: true,
                      message: t("validatePasswordRequired"),
                    },
                  })}
                />
                {errors.password && (
                  <TextDangerStyled>{errors.password.message}</TextDangerStyled>
                )}
                <button type="submit" className="container-login_btn-login">
                  {" "}
                  {t("loginUser")}{" "}
                </button>
                <button type="button" class="btn-Microsoft" onClick={handleLogin}> <img src={AzureIcon} />{t("loginSso")}</button>
                <a
                  className="container-login_txt-secondary"
                  onClick={() => history.push("/password-reset")}
                >
                  {t("forgot")}
                </a>

                <p className="container-login_checkbox">
                  <input
                    type="checkbox"
                    id="recuerdame"
                    name="recuerdame"
                    value="recuerdame"
                  />
                  <label htmlFor="recuerdame"> {t("rememberMe")} </label>
                  <br />
                </p>
              </form>
            </div>
          </div>

          {IS_APP_MANAGED_BY_REQUIRED ? (<div className="col-login-right">
            <div className="module-login--subscribe">
              <img src={LoginSuscribe} alt="" />
              <h1>
                <strong>{t("notAccount")}</strong>
              </h1>
              <a
                href={`${BASE_URL_SITE_TEACHER}/#/select-plan`}
                target="_blank"
              >
                {t("subscribe")}
              </a>
            </div>

            <div className="module-login--teacher">
              <img src={LoginTeacher} alt="" />
              <h1>{t("textRegister")}</h1>
              <a
                href={`${BASE_URL_SITE_TEACHER}/#/teachers`}
                target="_blank"
              >
                {t("register")}
              </a>
              <p className="txt-small">
                {t("grades")} <br />
                {t("matter")}
              </p>
            </div>

            <div className="module-login--institutional">
              <img src={LoginInstitucional} alt="" />
              <h1>
                {t("account")} <strong>{t("institutional")}</strong>
              </h1>
              <p>
                {t("textInstitutional")}
              </p>
            </div>
          </div>) : (
            <div className="col-login-right">
              <div className="module-login--institutional">
                <img src={LoginInstitucional} alt="" />
                <h1>
                  {t("account")} <strong>{t("institutional")}</strong>
                </h1>
                <p>
                  {t("textInstitutional")}
                </p>
              </div>

              <div className="module-login--teacher">
                <img src={LoginTeacher} alt="" />
                <h1>{t("textRegister")}</h1>
                <a
                  href={IS_APP_MANAGED_BY_REQUIRED ? `${BASE_URL_SITE_TEACHER}/#/teachers` : `${BASE_URL_SITE_TEACHER}/#/login`}
                  target="_blank"
                >
                  {t("register")}
                </a>
                <p className="txt-small">
                  {t("grades")} <br />
                  {t("matter")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginForm;
