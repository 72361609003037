import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../utils/utils";
import { getGradesOrdinals } from "../../../../utils/lesson";
import { getNameSubject } from "../../../../utils/subject";
import { getFileExtension } from "../../../../utils/file";

const BookItem = ({ book }) => {
  const history = useHistory();
  const { subjects } = useSelector((state) => ({
    subjects: state.subjects,
  }));
  const sp = book.Url.split("/");
  const file = sp[sp.length - 1];
  const ext = getFileExtension(file);

  let titleComponent;
  if (book.ForDownload === "0" && ext === "epub") {
    titleComponent = (
      <a onClick={() => history.push(`/students/library/${book.Id}`)}>
        {" "}
        {book.Title}{" "}
      </a>
    );
  } else if (book.ForDownload === "0" && ext === "pdf") {
    titleComponent = (
      <a onClick={() => history.push(`/students/library/${book.Id}`)}>
        {" "}
        {book.Title}{" "}
      </a>
    );
  } else {
    titleComponent = (
      <a href={book.Url} target="_blank">
        {" "}
        {book.Title}{" "}
      </a>
    );
  }

  return (
    <div className="four-col">
      <div className="general-module">
        <img
          className="image-library"
          src={
            book.UrlCover
              ? book.UrlCover
              : require("../../../../assets/images/generic-cover-library.jpg")
          }
          alt=""
        />
        <p className="list-library__title">{titleComponent}</p>
        <p className="list-library__description">{book.Description}</p>
        <p className="list-library__aditional">
          {getGradesOrdinals(book.Level, getLanguage())} -{" "}
          {getNameSubject(book.Subject, subjects)}
        </p>
      </div>
    </div>
  );
};

export default BookItem;
