const defaultState = 0;

function reducer(state = defaultState, action) {
  switch (action.type) {
    case "UNREAD_MESSAGES":
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
