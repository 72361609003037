import { getMessagesSents } from "../../api/message";
import loading from "./loading";

export const getMessagesSentSuccess = (metadata) => {
  return {
    type: "METADATA_SENT_MESSAGES",
    payload: metadata,
  };
};

export const getMessagesSentThunk = (page, pageSize) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const res = await getMessagesSents(page, pageSize);
      const metadata = res.data;
      dispatch(getMessagesSentSuccess(metadata));
    } catch {
    } finally {
      dispatch(loading(false));
    }
  };
};
