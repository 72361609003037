import React, { useState, useEffect } from "react";
import "./style.css";
import Concept from "./concept";
import LessonMenu from "./lesson-menu";
import Vocabulary from "./vocabulary";
import Example from "./example";
import Challenge from "./challenge";
import Practice from "./practice";
import DownloadLesson from "./download-lesson";
import AdditionalMaterial from "./additional-material";
import { getLessonById, getTriesHomeSchooler, getTriesSchool } from "../../../../api/lesson";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader } from "../../../common/styled-components/loader";
import TriesDialog from "./tries-dialog/tries-dialog";

const kids = ["K", "1", "2", "3"];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LessonDetail = () => {
  let { lessonId } = useParams();
  let query = useQuery();
  const history = useHistory();
  let location = useLocation();

  const { grade, subjects, subscriptionType } = useSelector((state) => ({
    grade: state.grade,
    subjects: state.subjects,
    subscriptionType: state.subscriptionType
  }));

  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState(query.get("tab") || "concept");
  const [open, setOpen] = useState(false);
  const [preCompleted, setPreCompleted] = useState(null);

  useEffect(() => {
    if (subscriptionType === "H") {
      getTriesHomeSchoolerAPI(lessonId);
    } else {
      getTriesSchoolAPI(lessonId);
    }
    getDetailModelLesson(lessonId);
  }, []);

  const getDetailModelLesson = async (lessonId) => {
    setLoading(true);
    try {
      const res = await getLessonById(lessonId);
      const data = res.data;
      setLesson(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getTriesHomeSchoolerAPI = async (lessonId) => {
    setLoading(true);
    try {
      const res = await getTriesHomeSchooler(lessonId);
      const data = res.data;
      setPreCompleted(data);
      if (data.PrePostRequired) {
        handleClickOpen();
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getTriesSchoolAPI = async (lessonId) => {
    setLoading(true);
    try {
      const res = await getTriesSchool(lessonId);
      const data = res.data;
      setPreCompleted(data);
      if (data.PrePostRequired) {
        handleClickOpen();
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.get("tab")) {
      history.push(location.pathname);
    }
  }, [tabActive]);

  const getNameSubject = (code, grade) => {
    let subject = subjects.find(
      (sub) => sub.Subject === code && sub.Level === grade
    );
    return subject ? subject : "";
  };

  const subject = lesson
    ? getNameSubject(
      lesson.SubjectCode,
      lesson.LevelCode
    )
    : null;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const redirectQuiz = () => {
    history.push(`/students/lesson/quiz/${preCompleted.QuizId}?subject=${lesson.SubjectCode}&level=${lesson.LevelCode}`);
  };

  return (
    <>
      <LessonMenu tabActive={tabActive} setTabActive={setTabActive} />
      {tabActive === "concept" && (
        <Concept
          lesson={lesson}
          kids={kids.includes(grade)}
          subject={subject}
          lessonId={lessonId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {tabActive === "vocabulary" && (
        <Vocabulary
          lesson={lesson}
          kids={kids.includes(grade)}
          subject={subject}
          lessonId={lessonId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {tabActive === "example" && (
        <Example
          lesson={lesson}
          kids={kids.includes(grade)}
          subject={subject}
          lessonId={lessonId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {tabActive === "practice" && (
        <Practice
          lesson={lesson}
          kids={kids.includes(grade)}
          subject={subject}
          lessonId={lessonId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {tabActive === "challenge" && (
        <Challenge
          lesson={lesson}
          kids={kids.includes(grade)}
          subject={subject}
          lessonId={lessonId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {tabActive === "additional" && (
        <AdditionalMaterial
          lesson={lesson}
          kids={kids.includes(grade)}
          subject={subject}
          lessonId={lessonId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {tabActive === "download" && (
        <DownloadLesson
          lesson={lesson}
          kids={kids.includes(grade)}
          subject={subject}
          lessonId={lessonId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {open && <TriesDialog open={open} onClose={handleClose} lesson={lesson} redirectQuiz={redirectQuiz} />}
      {loading && <Loader />}
    </>
  );
};

export default LessonDetail;
