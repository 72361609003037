import axios from "./interceptors";
import {
  PORT_API_GET_FORUM_BY_LEVEL_SUBJECT,
  PORT_API_GET_FORUM_DETAIL,
  PORT_API_FORUM_CREATE_POST,
  PORT_API_FORUM_UPDATE_POST,
  PORT_API_FORUM_DELETE_POST,
} from "../config/environment";

export const getForumsByLevelSubject = (data) => {
  return axios({
    method: "POST",
    url: `${PORT_API_GET_FORUM_BY_LEVEL_SUBJECT}/api/forums/student/`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const getForumDetails = (forumId, page, pageSize) => {
  page = page - 1;
  return axios.get(
    `${PORT_API_GET_FORUM_DETAIL}/api/forum/details/${forumId}/${page}/${pageSize}`
  );
};

export const createPost = (data) => {
  return axios({
    method: "POST",
    url: `${PORT_API_FORUM_CREATE_POST}/api/forum/post/create`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const updatePost = (data) => {
  return axios({
    method: "POST",
    url: `${PORT_API_FORUM_UPDATE_POST}/api/forum/post/modify`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const delelePost = (postId) => {
  return axios({
    method: "POST",
    url: `${PORT_API_FORUM_DELETE_POST}/api/forum/post/archive/${postId}`
  });
};
