import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  URL_FORM_CLICKUP_EN,
  URL_FORM_CLICKUP_SP,
} from "../../../config/environment";

const ContainerIframe = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 63.5%;

  @media (max-width: 450px) {
    padding-top: 100%;
  }
`;

const IFrameTemplateLesson = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const ClickupDialog = ({ onClose, open }) => {
  const { t, i18n } = useTranslation(["home"]);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("md");
  const [scroll] = React.useState("body");
  const language = i18n.language;

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        scroll={scroll}
      >
        <DialogContent dividers={scroll === "body"}>
          <div className="modal-text-right">
            <button className="close-windows" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          <div className="modal-comments-body">
            <ContainerIframe>
              <IFrameTemplateLesson
                src={
                  language === "en" ? URL_FORM_CLICKUP_EN : URL_FORM_CLICKUP_SP
                }
              />
            </ContainerIframe>
          </div>

          <div className="modal-footer-comments">
            <button
              type="button"
              className="button-modal-message-secondary"
              onClick={handleClose}
            >
              Cerrar
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ClickupDialog;
