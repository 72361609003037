import React, { useState, useEffect } from "react";
import "./style.css";
import LessonTitle from "../lesson-title";
import FontSizeChanger from "react-font-size-changer";
import { getLessonByIdConcept } from "../../../../../api/lesson";

const Concept = ({ lesson, kids, subject, lessonId, setLoading, loading }) => {
  const [concept, setConcept] = useState(null);

  useEffect(() => {
    getConceptByLesson();
  }, []);

  const getConceptByLesson = async () => {
    setLoading(true);
    try {
      const res = await getLessonByIdConcept(lessonId);
      const data = res.data;
      setConcept(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const removeDonwloadControlFromVideos = (htmlContent) => {
    if (htmlContent.includes("<video")) {
      htmlContent = htmlContent.replace("<video", "<video controlsList=\"nodownload\"");
    }
    return htmlContent;
  }

  let className = "wrapper-iframe";
  if (kids) {
    className = "wrapper-iframe-kids";
  }

  return (
    <>
      <div className="wrapper-content">
        <div className="center-container">
          <div className={className}>
            {lesson && <LessonTitle subject={subject} lesson={lesson} />}
            <FontSizeChanger
              targets={["#target-one .content"]}
              options={{
                stepSize: 2,
                range: 3,
              }}
            />
            <div id="target-one">
              <div className="content">
                <div className="content-font-size-changer"></div>
                <div className="only-image-inline"
                  onContextMenu={e => e.preventDefault()}
                  dangerouslySetInnerHTML={{
                    __html: concept ? removeDonwloadControlFromVideos(concept.Concept) : "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Concept;
