import React from "react";
import "./style.css";
import HeaderLogin from "../../login/header";
import FooterLogin from "../../login/footer";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { passwordResetSendEmail } from "../../../api/user";
import styled from "styled-components";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const TextDangerStyled = styled.p`
  color: #e6e134;
  font-size: 11px;
  margin: auto;
  padding: 0.25em;
  text-align: right;
`;

const SendEmailResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["recovery-pass"]);
  const { register, errors, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
      const res = await passwordResetSendEmail(data.email);
      toastr.success("", res.data.Messages[0]);
    } catch {}
  };

  return (
    <>
      <HeaderLogin />
      <div className="wrapper-container-login">
        <div className="container-forgot-password">
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="icon-pass">
              <i className="fas fa-lock"></i>
            </p>

            <h1 className="container-login_title"> {t("title")} </h1>
            <p className="text-login">{t("message")}</p>
            <input
              type="text"
              name="email"
              placeholder={t("inputEmail")}
              ref={register({
                required: {
                  value: true,
                  message: t("validEmailRequired"),
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("validEmailPattern"),
                },
              })}
            />
            {errors.email && (
              <TextDangerStyled>{errors.email.message}</TextDangerStyled>
            )}
            <p className="important-message">
              {" "}
              <i className="fas fa-exclamation-triangle"></i>
              {t("warning")}
            </p>

            <button type="submit" className="container-login_btn-login">
              {" "}
              {t("submit")}{" "}
            </button>

            <button
              type="submit"
              className="container-login_btn-login-secondary"
              onClick={() => history.push("/login")}
            >
              {" "}
              {t("gotoLogin")}{" "}
            </button>
          </form>
        </div>
      </div>
      <FooterLogin />
    </>
  );
};

export default SendEmailResetPassword;
