import React from "react";
import "./time-limit-dialog.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TimesOut from "../../../../../assets/images/image-modal-timesOut.svg";
import { useTranslation } from "react-i18next";


const TimeLimitDialog = ({ onClose, open, onGoBack }) => {

  const { t } = useTranslation(["quiz"]);

  const handleClose = () => {
    onGoBack();
    onClose();
  };

  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogContent>
          <div className="modal-text-right">
            <button className="close-windows" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>          

          <div className="container-modal-body modal-timesOut-body">
            <div className="modal-timesOut-body--image"><img src={TimesOut} alt="" /></div>
            <h1 className="modal-timesOut-body--title">{t("modalSorryTitle")}</h1>
            <p>{t("modalSorrySubTitle")}</p>
          </div>

          <div className="modal-footer modal-timesOut-footer">
            <button
              type="submit"
              className="button-modal-primary"
              onClick={handleClose}
            >
              {t("modalSorryClose")}
            </button>

          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TimeLimitDialog;
