import React from "react";
import "./style.css";
import LessonMarkIcon from "../../../../../../assets/images/lesson-mark.svg";
import LessonMenuPractice from "../../../../../../assets/images/LessonMenu-icon-practice.svg";
import LessonMenuChallege from "../../../../../../assets/images/LessonMenu-icon-challenge.svg";
import LessonMenuFinish from "../../../../../../assets/images/LessonMenu-icon-finish.svg";
import LessonMenuBack from "../../../../../../assets/images/LessonMenu-icon-back.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QuizMenu = ({ quiz }) => {
  const { t, i18n } = useTranslation(["quiz"]);
  const history = useHistory();

  const goBack = () => {
    switch (quiz.IsExam) {
      case "0":
        history.push(`/students/lesson/${quiz.LessonId}?tab=practice`);
        break;
      case "1":
        history.push(`/students/lesson/${quiz.LessonId}?tab=challenge`);
        break;
      default:
        history.goBack();
    }
  };

  return (
    <>
      <div className="wrapper-menu">
        <ul className="navigation-menu">
          {quiz && quiz.IsExam === "0" && (
            <li className="active">
              <a>
                {" "}
                <img src={LessonMenuPractice} />
                <span className="navigation-menu--item"> {t("practice")} </span>
              </a>
              <span className="lesson-mark">
                <img src={LessonMarkIcon} />
              </span>
            </li>
          )}
          {quiz && quiz.IsExam === "1" && (
            <li className="active">
              <a>
                {" "}
                <img src={LessonMenuChallege} />
                <span className="navigation-menu--item"> {t("challenge")} </span>
              </a>
              <span className="lesson-mark">
                <img src={LessonMarkIcon} />
              </span>
            </li>
          )}
          <li className="highlight-item">
            <a onClick={goBack}>
              {" "}
              <img src={LessonMenuBack} />
              <span className="navigation-menu--item"> {t("back")} </span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default QuizMenu;
