import React, { useState, useEffect } from "react";
import Menu from "../../menu";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "../../../common/styled-components/loader";
import { getForumDetails, delelePost } from "../../../../api/forum";
import AddPostDialog from "../add-post-dialog";
import ConfirmDialog from "../../../common/confirm-dialog";
import Pagination from "@material-ui/lab/Pagination";
import { getTimeZone } from "../../../../utils/utils";
import moment from "moment";
import "moment-timezone";

const EmptyListStyled = styled.p`
  color: #ccc;
  display: block
  justify-content: center;
  text-align: center;
  margin: 3em auto;

  & img {
    display: block;
    margin: 1em auto 0.25em auto;
    font-size: 45px;
    width: 175px;
    opacity: .5;
  }

  & i {
    display: block;
    margin: 1em auto 0.25em auto;
    font-size: 45px;
    width: 175px;
    opacity: .5;
  }
`;

const ForumDetail = () => {
  const { forumId } = useParams();
  const history = useHistory();
  const { t } = useTranslation(["forum"]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [count, setCount] = useState(0);
  const [forum, setForum] = useState({});
  const [author, setAuthor] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [itemSelected, setItemSelected] = useState({});

  useEffect(() => {
    getForumDetailById();
  }, []);

  const getForumDetailById = async () => {
    getForumDetailPaginate(forumId, page, pageSize);
  };

  const getForumDetailPaginate = async (forumId, page, pageSize) => {
    setLoading(true);
    try {
      const res = await getForumDetails(forumId, page, pageSize);
      const { ForumModel, ForumPostModels, TotalPages, AuthorName } = res.data;
      setAuthor(AuthorName);
      setForum(ForumModel);
      setPosts(ForumPostModels);
      setCount(TotalPages);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfirmDialog = (item) => {
    setItemSelected(item);
    setOpenConfirm(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirm(false);
  };

  const handleItemSelected = (item) => {
    setItemSelected(item);
    handleClickOpen();
  };

  const handleAddPost = () => {
    setItemSelected({});
    handleClickOpen();
  };

  const handleDeleteItem = async () => {
    setLoading(true);
    try {
      await delelePost(itemSelected.Id);
      setLoading(false);
      getForumDetailById();
      handleCloseConfirmDialog();
    } catch {
      setLoading(false);
    }
  };

  const handleChangePaginate = (event, value) => {
    setPage(value);
    getForumDetailPaginate(forumId, value, pageSize);
  };

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div>
            <button className="btn-back" onClick={() => history.goBack()}>
              <i className="fas fa-arrow-circle-left"></i> {t("goTo")}
            </button>
          </div>
          <div className="wrapper-forum">
            <h1>{forum.Title}</h1>

            <div className="forum-description-detail">
              <p>{forum.Description}</p>
              <p className="forum-description-detail__txt-secondary">
                <strong>{t("author")}:</strong> {author}
              </p>
              <p className="forum-description-detail__txt-secondary">
                <strong>{t("startDate")}:</strong>{forum.CreatedAt ? moment.utc(forum.CreatedAt, "MM/DD/YYYY h:mm a")
                  .tz(getTimeZone())
                  .format("MM/DD/YYYY hh:mm a") : ""}
              </p>
            </div>

            <div className="content-forum">
              <div className="content-forum__buttons">
                <button
                  type="submit"
                  className="button-secondary"
                  onClick={handleAddPost}
                >
                  {" "}
                  <i className="fas fa-plus"></i>Componer{" "}
                </button>
              </div>
              {posts.map((item, i) => (
                <div key={i} className="content-forum__post">
                  <div className="post__author"> {item.AuthorName} </div>
                  <div className="post__date">
                    {item.ForumPostModel.CreatedAt ? moment.utc(item.ForumPostModel.CreatedAt, "MM/DD/YYYY h:mm a")
                      .tz(getTimeZone())
                      .format("MM/DD/YYYY hh:mm a") : ""}
                  </div>
                  <div className="post__description">
                    {" "}
                    {item.ForumPostModel.Body}{" "}
                  </div>
                  {item.ShowEdit && (
                    <div className="content-forum__post--footer">
                      <button
                        className="button-w-icon"
                        onClick={() =>
                          handleOpenConfirmDialog(item.ForumPostModel)
                        }
                      >
                        {" "}
                        <i className="fas fa-times"></i> {t("delete")}{" "}
                      </button>
                      <button
                        className="button-w-icon"
                        onClick={() => handleItemSelected(item.ForumPostModel)}
                      >
                        {" "}
                        <i className="fas fa-pen"></i> {t("edit")}{" "}
                      </button>
                    </div>
                  )}
                </div>
              ))}
              {!posts.length && !loading && (
                <EmptyListStyled>
                  <i className="fas fa-book-reader"></i> {t("emptyForum")}
                </EmptyListStyled>
              )}
              {count > 1 && (
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChangePaginate}
                />
              )}
            </div>
          </div>
          {open && (
            <AddPostDialog
              open={open}
              onClose={handleClose}
              forumId={forumId}
              getForumDetailById={getForumDetailById}
              item={itemSelected}
            />
          )}
          {openConfirm && (
            <ConfirmDialog
              open={openConfirm}
              onClose={handleCloseConfirmDialog}
              handleDeleteItem={handleDeleteItem}
            />
          )}
          {loading && <Loader />}
        </div>
      </div>
    </>
  );
};

export default ForumDetail;
